<template>
  <tj-dialog
    v-model="visible"
    title="审核"
    @close="onClose"
  >
    <el-form ref="formRef" label-width="120px" :model="form" :rules="rules">
      <el-form-item label="审核：">
        <el-radio-group v-model="form.status">
          <el-radio-button :label="1">通过</el-radio-button>
          <el-radio-button :label="2">失败</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.status === 2"
        label="审核失败原因："
        prop="refuse"
      >
        <el-input v-model="form.refuse" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref, nextTick } from 'vue';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const emit = defineEmits(['submit']);

let id = 0;

const defaultDefailInfo = {
  status: 1,
  refuse: '',
};

const form = ref({ ...defaultDefailInfo });
const formRef = ref();

const rules = {
  refuse: { required: true, message: '审核失败原因不能为空' },
};

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((nodeId) => {
  id = nodeId;
  nextTick(() => {
    formRef.value.clearValidate();
  });
});
function onClose() {
  form.value = { ...defaultDefailInfo };
}

function submit() {
  formRef.value.validate((valid) => {
    if (valid) {
      request.post(`/admin/order/companyReview/${id}`, form.value).then((data) => {
        console.log(data);
        emit('submit');
      });
    }
  });
}

defineExpose({
  open,
});
</script>
